<template>
    <div class="page_wrap newsDetail">
        <div class="page_content newsPage-content ql-editor" >
             <h2 class="newsPage-content-title">
                 {{ news.title }}
             </h2>
             <div class="newsPage-content-nextTitle" style="margin-top:0.2rem">
                 <span class="nextTitle-item">日期：{{  formatTime(news.createDate) }}</span>
                 <span class="nextTitle-item">创建人：{{ news.publishUser }}</span>
                 <span class="nextTitle-item">分类：{{ news.contentType }}</span>
                 <!-- <el-link :href="news.wxUrl" target="_blank"  type="primary">微信链接</el-link> -->
             </div>
             <div class="newsPage-content-content"  style="text-indent:2em" v-html="news.content">
             </div>
             <!-- <div >
            <div class="newsPage-content-content" >
                 <div class="paragraphs" v-for="(item,index) of news.paragraphs" :key="index">
                  <div class="paragrah">
                      <div class="content" style="text-indent:2em">{{item.paragraph}}</div>
                      <div v-if="item.url && news.contentLayoutType!==2" :style="{ 'backgroundImage': `url(${item.url})`,'height':'400px','backgroundSize':'cover', 'background-repeat':'no-repeat', 'background-position':'center','margin-left':'2em' }"  class="image"></div>
                      <div v-if="item.url && news.contentLayoutType==2"  :style="{ 'backgroundImage': `url(${item.url})`,'height':'600px','backgroundSize':'contain', 'background-repeat':'no-repeat', 'background-position':'center','margin-left':'2em' }"  class="image"></div>
                  </div>
                 </div>
                 <div class="subtitle">
                    {{ news.subtitle}}
                 </div>
                 <div class="subContent" v-for="item of news.subContent" :key="item.id">
                     <div class="subContent-title">{{item.subtitle}}</div>
                     <div class="content" style="text-indent:2em">{{item.content}}</div>
                 </div>
             </div>
             </div> -->
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import api from '@/api/api'
import DateUtils from "@/assets/filters/dateUtils";
export default {
    data(){
        return{
            // news:JSON.parse(this.$route.query.info)
            news:'',
            
        }
    },
    methods:{
        queryNewsDetail(){
            let id=this.$route.query.id
            api.newsDetail(id).then(res=>{
                if(res.code==200 && res.data){
                this.content=res.data.content
                this.news=res.data
                }
            })
        },
        formatTime(val){
           return DateUtils.dateFormat(new Date(val))
        }
    },
    computed:{
    //   news(){
    //      return  JSON.parse(localStorage.getItem('info'))
    //   }
    },
    created(){
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.queryNewsDetail()
        // console.log(JSON.parse(this.$route.query.info))
    },
    beforeDestroy(){
        localStorage.removeItem('info')
        
    }
}
</script>
<style lang="scss" scoped>
.paragraphs > div {
    width: 100%;
    margin: auto ;
}
.newsPage{
   &-content{
       padding:0.24rem;
       margin-top:0.2rem;
       &-title{
           text-align: center;
           color:#303343;
           font-size: 0.2rem;
       }
       &-nextTitle{
           display: flex;
           justify-content: center;
            .nextTitle-item{
                font-size: 0.12rem;
                color:#909399;
                margin:0.05rem 0.24rem;
            }
       }
       &-content{
           line-height: 0.5rem;
           padding:0.4rem 2rem;
           font-size: 0.16rem;
       }
   }
}
  .newsPage-content-content{
    .paragraph{
        margin-bottom: 20px;;
        font-size: 0.16rem;
        line-height: 0.5rem;
        .content{
        
          text-indent: 2em!important
        }
    }
    .subtitle{
        text-align: center;
        font-weight: 600;
        font-size: 0.18rem;
    }
  
}
  .subContent-title{
       text-align: center;
        font-weight: 600;  
    }
    .image{
        margin: auto;
    }
    // .image{
    //     width: 300px;
    //     height: 300px;
    //     background-size:'cover';
    //     background-repeat:'no-repeat';
    //     background-position:'center' 
    // }
</style>
<style lang="scss" >
    .ql-editor{
        img{
            max-width: 700px;
            width: 80%!important;
        }
    }                   
</style>
